import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import ServicesTr from '../routes/healthcareTR';
import ServicesEn from '../routes/healthcareEn';
import HealtCareTr from '../routes/servicesTR';
import PaymentPage from '../routes/PaymentPage';
import PrivacyPolicy from '../routes/PrivacyPolicy/PrivacyPolicy.tr-TR';
import PrivacyPolicyEn from '../routes/PrivacyPolicy/PrivacyPolicy.en-Us';
import Payment from '../routes/Payment';

const Navigation = () => {
  return (
    <BrowserRouter basename="/">
      <Routes>
        {/* <Route path="/" element={<Navigate replace to={"/tr-Tr"} />} />
        <Route path="/tr-Tr" element={<ServicesTr />} />
        <Route path="/en-Us" element={<ServicesEn />} />
        <Route path="/tr-Tr/HealtCare-Provider" element={<HealtCareTr />} />
        <Route path="/tr-Tr/Privacy-Policy" element={<PrivacyPolicy />} />
        <Route path="/en-Us/Privacy-Policy" element={<PrivacyPolicyEn />} /> */}
        {/* <Route path="/Payment-Control" element={<PaymentPage />} /> */}
        <Route path="/" element={<Payment />}></Route>
        <Route path="/payment-control" element={<Payment />}></Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Navigation;
