import axios from 'axios';
import React, { useEffect, useState } from 'react';
// import { useRouter } from 'next/router';
import '../payment.css';
// import { useTranslation } from 'react-i18next';

const Payment = () => {
  const [pennding, setPennding] = useState(true);
  const [result, setResult] = useState();
  const [intervalState, setIntervalState] = useState();
  const [timerControl, setTimerControl] = useState(false);
  const [CountDown, setCountDown] = useState(15);
  // const { t } = useTranslation();

  async function CapturMokaPayment(OtherTrxCode) {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'text/plain');
    myHeaders.append(
      'Authorization',
      'Bearer ' +
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3ByaW1hcnlzaWQiOiIxMSIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6IkFkbWluaXN0cmF0b3IiLCJVc2VySWQiOiI3IiwiVXNlck5hbWUiOiJMaW5kYSIsImV4cCI6MTcwMDczOTQ0NywiaXNzIjoiS2xpbmlreWEiLCJhdWQiOiJsb2NhbGhvc3QifQ.xzuDAblue1SKwfAHTrsCXTFgP8SOvD0LP9osOS8S3N0'
    );
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
    };
    try {
      const url = `https://klinikya-api.azurewebsites.net/api/Appointment/capture-moka-payment?OtherTrxCode=${OtherTrxCode}`;
      const urlReady = `https://klinikya-api.azurewebsites.net/api/Payment/capture-threed-payment?OtherTrxCode=${OtherTrxCode}`;
      axios.post(url).then((res) => {
        if (res.data.Code == 1) {
          setResult(res.data);
          setTimerControl(true);
        } else {
          // setResult(res.data);
          fetch(urlReady, requestOptions)
            .then((response) => response.json())
            .then((res2) => {
              if (res2?.Code == 1) {
                setResult(res2);
                setTimerControl(true);
              } else {
                setResult(res2);
              }
            });
        }
      });
    } catch (error) {
    } finally {
      setPennding(false);
    }
  }

  function NavigateFunction() {
    if (result) {
      if (result.Code == 14) {
        // Payment Decline
        alert('Payment Decline');
        window.location.replace(`http://portal.klinikya.com/`);
      } else if (result.Code == 1) {
        window.location.replace(`http://portal.klinikya.com/`);
      }
    } else {
      //   alert('Boş');
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountDown((prevCount) => prevCount - 1);
    }, 1000);
    setIntervalState(intervalId);
  }, []);

  useEffect(() => {
    if (CountDown < 1) {
      clearInterval(intervalState);
      NavigateFunction();
    }
  }, [CountDown]);

  useEffect(() => {
    setPennding(true);
    const queryParams = new URLSearchParams(window.location.search);
    const OtherTrxCode = queryParams.get('OtherTrxCode');
    setTimeout(() => CapturMokaPayment(OtherTrxCode), 1000);
  }, []);
  return (
    <div className="wrapper">
      <div className="MainView">
        {pennding ? (
          <div className={`$"MinTextPart} $"PenndingText}`}>Bekleyiniz...</div>
        ) : (
          <div className="MinTextPart">
            <div className={result?.Code == 1 ? 'SuccessText' : 'FailedText'}>
              {result ? (result?.Code == 1 ? 'Başarılı' : 'Başarısız') : null}
            </div>
            <div className={''}>
              {result
                ? CountDown < 1
                  ? 'Git'
                  : `${CountDown} sn sonra yönlendirileceksiniz`
                : null}
            </div>
            <a
              href="http://portal.klinikya.com/"
              onClick={() => {
                clearInterval(intervalState);
              }}
              className="GoMain"
            >
              Uygulamaya Git
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default Payment;
